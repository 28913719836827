import { Helmet } from "react-helmet";


const General = ({ title, content, children }) => (

    <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={content} />
            <meta charset="utf-8" />
            <meta name="keywords" content="metronic, bootstrap, bootstrap 5, angular, VueJs, React, Asp.Net Core, Rails, Spring, Blazor, Django, Express.js, Node.js, Flask, Symfony & Laravel starter kits, admin themes, web design, figma, web development, free templates, free admin themes, bootstrap theme, bootstrap template, bootstrap dashboard, bootstrap dak mode, bootstrap button, bootstrap datepicker, bootstrap timepicker, fullcalendar, datatables, flaticon" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Metronic - Bootstrap Admin Template, HTML, VueJS, React, Angular. Laravel, Asp.Net Core, Ruby on Rails, Spring Boot, Blazor, Django, Express.js, Node.js, Flask Admin Dashboard Theme & Template" />
            <meta property="og:url" content="https://keenthemes.com/metronic" />
            <meta property="og:site_name" content="Keenthemes | Metronic" />
            <link rel="canonical" href="https://preview.keenthemes.com/metronic8" />
            <link rel="shortcut icon" href="/assets/media/logos/favicon.ico" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700" />
            <link href="/assets/plugins/global/plugins.bundle.css" rel="stylesheet" type="text/css" />
            <link href="/assets/css/style.bundle.css" rel="stylesheet" type="text/css" />

            <script src="/assets/plugins/global/plugins.bundle.js"/>
            <script src="/assets/js/scripts.bundle.js"/>
            
        </Helmet>
        <div id="kt_body" className="auth-bg bgi-size-cover bgi-position-center bgi-no-repeat">

            <div className="d-flex flex-column flex-root">
                <style>
                    {`
                        body {
                        background-image: url(${process.env.PUBLIC_URL}/assets/media/auth/bg8.jpg);
                        }

                        [data-bs-theme="dark"] body {
                        background-image: url(${process.env.PUBLIC_URL}/assets/media/auth/bg8-dark.jpg);
                        }
                    `}
                </style>
                <div className="d-flex flex-column flex-center flex-column-fluid">
                    <div className="d-flex flex-column flex-center text-center p-10">
                        <div className="card card-flush w-md-650px py-5">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default General;