import { addSubsRequest } from "features/subscription";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const calculateProportionalPrice = (months, monthlyPrice, annualPrice) => {
  if (months === 12) {
    return annualPrice;
  }
  const proportionalPrice =
    monthlyPrice + ((annualPrice - monthlyPrice) / 11) * (months - 1);

  // Ensure the price does not exceed the discounted annual price
  const finalPrice = Math.min(proportionalPrice, annualPrice);

  // Round to the nearest 50 DZD
  return Math.round(finalPrice / 50) * 50;
};

const SubsInfo = () => {
  const dispatch = useDispatch();

  const { plans } = useSelector((state) => state.subscription);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [months, setMonths] = useState(1);
  const [price, setPrice] = useState(500);
  const [selectedYear, setSelectedYear] = useState(null);

  const handlePlanChange = (selectedOption) => {
    setSelectedPlan(selectedOption.plan);
    setSelectedYear(null);
    calculatePrice(months, selectedOption.plan);
  };

  // Handle faculty change
  const handleFacultyChange = (selectedOption) => {
    setSelectedFaculty(selectedOption);
  };
  const handleRangeChange = (event) => {
    const newMonths = Number(event.target.value);
    setMonths(newMonths);
    if (selectedPlan) {
      calculatePrice(newMonths, selectedPlan);
    }
  };

  const calculatePrice = (months, plan) => {
    const monthlyPrice = parseFloat(plan.monthly_price);
    const annualPrice = parseFloat(plan.annual_price);

    const calculatedPrice = calculateProportionalPrice(
      months,
      monthlyPrice,
      annualPrice
    );
    setPrice(calculatedPrice);
  };

  const yearOptions = [
    { value: "1ere", label: "1ère année" },
    { value: "2eme", label: "2ème année" },
    { value: "3eme", label: "3ème année" },
    { value: "4eme", label: "4ème année" },
    { value: "5eme", label: "5ème année" },
    { value: "6eme", label: "6ème année" },
  ];

  const facultyOptions = [
    { value: 25, label: "Constantine" },
    { value: 31, label: "Oran" },
  ];
  // Generate plan options based on the selected faculty
  const filteredPlans = plans.filter((plan) => {
    if (selectedFaculty && selectedFaculty.label === "Oran") {
      return plan.title === "RESIDANAT";
    }
    return true;
  });

  const planOptions = filteredPlans.map((plan) => ({
    value: plan.id,
    label: plan.title,
    plan,
  }));
  const showSwalError = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Veuillez sélectionner tout les champs",
      icon: "error",
      buttonsStyling: true,
      confirmButtonColor: "#dc3545",
    });
  };

  const showSwalServerError = (error) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: error,
      icon: "error",
      buttonsStyling: true,
      confirmButtonColor: "#dc3545",
    });
  };
  const showSwalSuccess = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Votre demande a bien été enregistrée. Nous vous invitons à consulter votre boîte e-mail pour les instructions suivantes.",
      icon: "success",
      buttonsStyling: true,
      confirmButtonColor: "#dc3545",
    });
  };

  const handleSubmit = () => {
    if (!selectedPlan || !selectedFaculty || !price) {
      showSwalError();
      return;
    }

    const data = {
      plan: selectedPlan.id,
      faculty: selectedFaculty.value,
      year: selectedYear ? selectedYear.label : "Non spécifié",
      months,
      price,
    };


    dispatch(addSubsRequest(data))
      .unwrap()
      .then((result) => {
        showSwalSuccess();
      })
      .catch((error) => {
        showSwalServerError(error.error);
      });
  };
  return (
    <div className="d-flex flex-column">
      <div className="row">
        <div className="col-lg-6 mb-10 mb-lg-0">
          <div className="d-flex flex-column mb-8 fv-row mt-3">
            <h2 className="mb-3">Demandez un abonement:</h2>
            <div className="pt-2">
              <div className="d-flex flex-column align-items-start mb-7">
                <label className="d-flex form-label fs-5 fw-semibold">
                  Sélectionnez une faculté :
                </label>
                <Select
                  options={facultyOptions}
                  onChange={handleFacultyChange}
                  placeholder="Choisir une faculté"
                  className="w-100"
                />
              </div>
              <div className="d-flex flex-column align-items-start mb-7">
                <label className="d-flex form-label fs-5 fw-semibold">
                  Sélectionnez un plan :
                </label>
                <Select
                  options={planOptions}
                  placeholder="Choisir un plan"
                  onChange={handlePlanChange}
                  className="w-100"
                  isDisabled={!selectedFaculty}
                />
              </div>
              {selectedPlan && selectedPlan.title === "EXTERNAT" && (
                <div className="d-flex flex-column align-items-start mb-7">
                  <label className="d-flex form-label fs-5 fw-semibold">
                    Sélectionnez une année :
                  </label>
                  <Select
                    options={yearOptions}
                    placeholder="Choisir une année"
                    onChange={(option) => {
                      setSelectedYear(option);
                    }}
                    className="w-100"
                  />
                </div>
              )}
              <div className="d-flex flex-column align-items-start mb-7">
                <label htmlFor="months-range" className="form-label">
                  Nombre de mois : {months} ({price} DZD)
                </label>
                <input
                  id="months-range"
                  type="range"
                  min="1"
                  max="12"
                  step="1"
                  value={months}
                  onChange={handleRangeChange}
                  className="form-range"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div class="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-4 bg-lighten">
                <div class="d-flex flex-stack pb-3">
                  <div class="mb-8">
                    <span class="badge badge-light-secondary">
                      Paiement en attente
                    </span>
                  </div>
                  <button
                    class="btn btn-sm btn-warning text-dark"
                    onClick={handleSubmit}
                  >
                    Envoyer
                  </button>
                </div>

                <h6 class="mb-8 fw-bolder text-gray-700">
                  DÉTAILS DE PAIEMENT
                </h6>
                <div class="mb-6">
                  <div class="fw-semibold text-gray-700 fs-7">Faculté:</div>
                  <div class="fw-bold text-gray-800 fs-4">
                    {selectedFaculty
                      ? selectedFaculty.label
                      : "Aucune sélection"}
                  </div>
                </div>
                <div class="mb-6">
                  <div class="fw-semibold text-gray-700 fs-7">Plan:</div>
                  <div className="fw-bold text-gray-800 fs-4">
                    {selectedPlan
                      ? `${selectedPlan.title}${
                          selectedYear ? ` (${selectedYear.label})` : ""
                        }`
                      : "Aucun plan sélectionné"}
                  </div>
                </div>
                <div class="mb-6">
                  <div class="fw-semibold text-gray-700 fs-7">Prix:</div>
                  <div class="fw-bold text-gray-800 fs-2">{price} DZD</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="nav flex-column">
            {plans &&
              plans.map((plan, index) => (
                <label
                  key={index}
                  className={`btn btn-outline btn-color-dark d-flex flex-stack text-start p-6 mb-6`}
                >
                  <div className="d-flex align-items-center me-2">
                    <div className="flex-grow-1">
                      <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                        {plan.title}
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="mb-2">DZD</span>
                    <span className="fs-4 fw-bold"> {plan.monthly_price}</span>
                    <span className="fs-6 fw-bold"> /mois</span>
                  </div>
                  <div className="ms-5">
                    <span className="fs-4 fw-bold"> {plan.annual_price}</span>
                    <span className="fs-6 fw-bold"> /an</span>
                  </div>
                </label>
              ))}
          </div>
          <div className="tab-content rounded bg-light p-10">
            <div className="tab-pane show active">
              <div className="d-flex flex-column mb-8 fv-row">
                <div className="pt-1">
                  <div className="d-flex align-items-center mb-7">
                    <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                      QCMs Organisés par Cours et Module
                    </span>
                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </div>
                  <div className="d-flex align-items-center mb-7">
                    <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                      Un Seul Utilisateur
                    </span>
                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </div>
                  <div className="d-flex align-items-center mb-7">
                    <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                      Mode de Révision
                    </span>
                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </div>
                  <div className="d-flex align-items-center mb-7">
                    <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                      Modes d'Examen et d'Examen Blancs
                    </span>
                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </div>
                  <div className="d-flex align-items-center mb-7">
                    <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                      Notes et Playlists Personnelles
                    </span>
                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </div>
                  <div className="d-flex align-items-center mb-7">
                    <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                      Accès à des Sources Fiables et Spécifiques
                    </span>
                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubsInfo;
