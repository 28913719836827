import { login, resetRegistered } from "features/auth";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Auth from "components/auth/Auth";
import useRecaptcha from "hooks/useRecaptcha";

const LoginPage = () => {
  const dispatch = useDispatch();
  useRecaptcha("user_login");

  const recaptcha_token = useSelector((state) => state.recaptcha.token);
  const {
    loading,
    isAuthenticated,
    registered,
    error,
    verifyEmailStatus,
    reset_password_state,
  } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (registered || reset_password_state || verifyEmailStatus)
      dispatch(resetRegistered());
  }, [dispatch, registered, reset_password_state, verifyEmailStatus]);

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    const lowercaseEmail = email.toLowerCase(); // Convert email to lowercase

    if (recaptcha_token) {
      dispatch(login({ email: lowercaseEmail, password, recaptcha_token }));
    }
  };

  if (isAuthenticated) return <Navigate to="/dashboard" />;

  return (
    <Auth title="Elmajor | Connexion" content="Login">
      {verifyEmailStatus && (
        <div className="alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row mb-10 position-fixed top-0 end-0 p-3 z-index-3">
          <i className="ki-duotone ki-search-list fs-2hx text-success me-4 mb-5 mb-sm-0">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>

          <div className="d-flex flex-column pe-0 pe-sm-10">
            <span>Félicitations ! Votre compte a bien été activé</span>
          </div>
          <button
            type="button"
            className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
            data-bs-dismiss="alert"
          >
            <i className="ki-duotone ki-cross fs-1 text-success">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </button>
        </div>
      )}
      {reset_password_state && (
        <div className="alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row mb-10 position-fixed top-0 start-0 p-3 z-index-3">
          <i className="ki-duotone ki-search-list fs-2hx text-success me-4 mb-5 mb-sm-0">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>

          <div className="d-flex flex-column pe-0 pe-sm-10">
            <span>Votre mot de passe a été modifié.</span>
          </div>
          <button
            type="button"
            className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
            data-bs-dismiss="alert"
          >
            <i className="ki-duotone ki-cross fs-1 text-success">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </button>
        </div>
      )}
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 mt-20 pb-30">
        <div className="d-flex flex-center flex-column flex-lg-row-fluid mb-13">
          <div className="w-lg-500px p-10">
            <div className="text-center mb-11">
              <h1 className="text-dark fw-bolder mb-3">se connecter</h1>
              <div className="text-gray-500 fw-semibold fs-6">
                Connectez-vous
              </div>
            </div>
            {error && error.detail && (
              <div className="fv-plugins-message-container invalid-feedback">
                <div data-field="confirm-password" data-validator="identical">
                  {error.detail}
                </div>
              </div>
            )}
            {error && error.non_field_errors && <Navigate to="/verify-email" />}
            <form className="form w-100 mb-10" onSubmit={onSubmit}>
              <div className="fv-row mb-8">
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  autoComplete="on"
                  className="form-control bg-transparent"
                  onChange={onChange}
                  value={email}
                  required
                />
                {error && error.email && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div
                      data-field="confirm-password"
                      data-validator="identical"
                    >
                      {error.email}
                    </div>
                  </div>
                )}
              </div>
              <div
                className="fv-row mb-3 position-relative"
                data-kt-password-meter="true"
              >
                <input
                  type="password"
                  placeholder="Mot de passe"
                  name="password"
                  autoComplete="off"
                  className="form-control bg-transparent "
                  onChange={onChange}
                  value={password}
                  required
                />
                <span
                  className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                  data-kt-password-meter-control="visibility"
                >
                  <i className="ki-duotone ki-eye-slash fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                  <i className="ki-duotone ki-eye d-none fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                </span>
              </div>
              <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                <div></div>
                <Link to="/reset-password" className="link-primary">
                  Mot de passe oublié ?
                </Link>
              </div>
              <div className="d-grid mb-10">
                {loading ? (
                  <button disabled className="btn btn-primary">
                    <span className="">
                      S'il vous plaît, attendez...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    <span className="indicator-label">Se connecter</span>
                  </button>
                )}
              </div>
              <div className="text-gray-500 text-center fw-semibold fs-6">
                Pas encore membre ?
                <Link to="/register" className="link-primary">
                  {" "}
                  S'inscrire
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Auth>
  );
};

export default LoginPage;
