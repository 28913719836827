import { Navigate } from 'react-router-dom';
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetQuestions, resetExamQuestions, resetCourses, resetSources } from "features/medecine";

import { resetRegistered, checkAuth } from "features/auth";

import Layout from 'components/dashboard/Layout';
import Sidebar from 'components/dashboard/SideBar';
import Home from 'components/dashboard/Home';
import UpgradePlanModal from 'components/dashboard/subscription/UpgradePlanModal';

const DashboardPage = () => {
	const dispatch = useDispatch();
	const { isAuthenticated, registered, user } = useSelector(state => state.user);

	useEffect(() => {
		const handleGlobalClick = (event) => {

			const asideElement = document.getElementById('kt_aside');
			const isClickInsideSidebar = asideElement?.contains(event.target);

			if (!isClickInsideSidebar) {
				asideElement.classList.remove('drawer-on');
			}
		};

		const initializeSidebarDrawer = () => {
			const asideElement = document.getElementById('kt_aside');
			if (window.innerWidth <= 767) {
				asideElement.classList.add('drawer-on');
			}
		};

		// Initialize sidebar drawer on component mount
		initializeSidebarDrawer();

		// Attach global click event listener
		document.addEventListener('click', handleGlobalClick);

		// Cleanup: Remove event listener on component unmount
		return () => {
			document.removeEventListener('click', handleGlobalClick);
		};
	}, []);

	useEffect(() => {

		// Dispatch the checkAuth action initially when the component mounts
		dispatch(checkAuth());
		dispatch(resetQuestions());
		dispatch(resetCourses());
		dispatch(resetSources());
		dispatch(resetExamQuestions());
		// Create an interval to dispatch the checkAuth action every 5 minutes (300000 milliseconds)
		const interval = setInterval(() => {
			dispatch(checkAuth());
		}, 300000);

		// Clean up the interval when the component unmounts to avoid memory leaks
		return () => clearInterval(interval);

	}, [dispatch]);

	useEffect(() => {
		if (registered) {
			dispatch(resetRegistered());
		}

	}, [dispatch, registered]);

	if (!user && !isAuthenticated) {
		return <Navigate to='/login' />;
	}


	return (
		<Layout title='Elmajor | Dashboard' content='Dashboard page'>
			<div className="d-flex flex-column flex-root">
				<div className="page d-flex flex-row flex-column-fluid">
					<Sidebar />
					<Home />
				</div>
			</div>
			<UpgradePlanModal />
		</Layout>
	);
};

export default DashboardPage;