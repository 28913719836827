import { Helmet } from "react-helmet";
import React from "react";

const Layout = ({ title, children }) => {

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="The most advanced Bootstrap 5 Admin Theme with 40 unique prebuilt layouts on Themeforest trusted by 100,000 beginners and professionals. Multi-demo, Dark Mode, RTL support and complete React, Angular, Vue, Asp.Net Core, Rails, Spring, Blazor, Django, Express.js, Node.js, Flask, Symfony & Laravel versions. Grab your copy now and get life-time updates for free."
        />
        <meta
          name="keywords"
          content="metronic, bootstrap, bootstrap 5, angular, VueJs, React, Asp.Net Core, Rails, Spring, Blazor, Django, Express.js, Node.js, Flask, Symfony & Laravel starter kits, admin themes, web design, figma, web development, free templates, free admin themes, bootstrap theme, bootstrap template, bootstrap dashboard, bootstrap dak mode, bootstrap button, bootstrap datepicker, bootstrap timepicker, fullcalendar, datatables, flaticon"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Metronic - Bootstrap Admin Template, HTML, VueJS, React, Angular. Laravel, Asp.Net Core, Ruby on Rails, Spring Boot, Blazor, Django, Express.js, Node.js, Flask Admin Dashboard Theme & Template"
        />
        <meta property="og:site_name" content="Keenthemes | Metronic" />
        <link rel="shortcut icon" href="assets/media/logos/favicon.ico" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700"
        />
        <link
          href="assets/plugins/global/plugins.bundle.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="assets/css/style.bundle.css"
          rel="stylesheet"
          type="text/css"
        />
        <body
          id="kt_body"
          className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-enabled"
        />
        <script src="/assets/plugins/global/plugins.bundle.js"></script>
        <script src="assets/js/scripts.bundle.js"></script>
        <script src="/assets/js/custom/utilities/modals/new-target.js"></script>
        <script src="/assets/js/elmajor.js"></script>

        <script src="https://cdn.amcharts.com/lib/5/themes/Animated.js"></script>
      </Helmet>

      {children}
    </>
  );
};

export default Layout;
