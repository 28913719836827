import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getPlans = createAsyncThunk(
    'subscription/plans',
    async (_, thunkAPI) => {
        try {
            const res = await fetch('/api/subscriptions/plans', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });

            const data = await res.json();

            if (res.status === 200) {
                const { dispatch } = thunkAPI;

                // dispatch(getActiveSubscription());

                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {

            return thunkAPI.rejectWithValue(err.response.data);
        }
    });

export const getActiveSubscription = createAsyncThunk(
    'subscription/active-subscription',
    async (_, thunkAPI) => {
        try {
            const res = await fetch('/api/subscriptions/active-subscription', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });

            const data = await res.json();

            if (res.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {

            return thunkAPI.rejectWithValue(err.response.data);
        }
    });

export const addSubscription = createAsyncThunk(
    'subscriptions/add',
    ({ activation_code }, thunkAPI) => {
        const body = JSON.stringify({
            activation_code
        });
        return fetch('/api/subscriptions/add/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body,
        })
            .then(res => {
                if (res.status === 201) {
                    return res.json();
                } else {
                    return thunkAPI.rejectWithValue(res.json());
                }
            })
            .catch(err => thunkAPI.rejectWithValue(err.response.data));
    }
);
export const addSubsRequest = createAsyncThunk(
    'subscriptions/request',
    ({ plan, faculty, year, months, price }, thunkAPI) => {
        const body = JSON.stringify({
            plan,
            faculty,
            year,
            months,
            price
        });

        return fetch('/api/subscriptions/request/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body,
        })
            .then(res => {
                if (res.status === 201) {
                    return res.json();
                } else {
                    return res.json().then(error => thunkAPI.rejectWithValue(error));
                }
            })
            .catch(err => thunkAPI.rejectWithValue(err));
    }
);
const initialState = {
    loading: false,
    plans: [],
    error: null,
    subscription: null,
    request: null
}

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        resetError: state => {
            state.error = null;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getPlans.pending, state => {
                state.loading = true;
            })
            .addCase(getPlans.fulfilled, (state, action) => {
                state.loading = false;
                state.plans = action.payload;
                state.error = null;
            })
            .addCase(getPlans.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addSubsRequest.pending, state => {
                state.loading = true;
            })
            .addCase(addSubsRequest.fulfilled, (state, action) => {
                state.loading = false;
                state.request = action.payload;
                state.error = null;
            })
            .addCase(addSubsRequest.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getActiveSubscription.pending, state => {
                state.loading = true;
            })
            .addCase(getActiveSubscription.fulfilled, (state, action) => {
                state.loading = false;
                state.subscription = action.payload;
            })
            .addCase(getActiveSubscription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.subscription = null;
            })
            .addCase(addSubscription.pending, state => {
                state.loading = true;
            })
            .addCase(addSubscription.fulfilled, (state, action) => {
                state.loading = false;
                state.subscription = action.payload;
                state.error = null;
            })
            .addCase(addSubscription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.subscription = null;

            })
    },
});
export const { resetError } = subscriptionSlice.actions
export default subscriptionSlice.reducer