import { useSelector, useDispatch } from "react-redux";
import { logout, register } from "features/auth";
import { Link, Navigate } from "react-router-dom";

import General from "components/auth/General";
import { resendActivation } from "features/auth";

const VerifyEmailPage = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, error } = useSelector((state) => state.user);
  const resend_activation = () => {
    dispatch(resendActivation(user.email));
  };

  return (
    <General title="Elmajor | Vérification de l'e-mail" content="Register">
      <div className="card-body py-15 py-lg-20">
        <div className="mb-4">
          <a href="/" className="">
            <img
              alt="Logo"
              src="assets/media/logos/demo3.png"
              className="h-100px"
            />
          </a>
        </div>
        <h1 className="fw-bolder text-gray-900 mb-5">Vérifiez votre e-mail</h1>
        <div className="fs-6 mb-3 p-10">
          <span className="fw-semibold text-gray-900">
            Un e-mail d'activation a été envoyé. Veuillez vérifier votre boîte
            de réception et suivre le lien pour activer votre compte. Si vous ne
            trouvez pas l'e-mail, vérifiez le dossier de courrier indésirable.
            Besoin d'aide ? Contactez-nous.
          </span>
          <br />
          <span className="fw-semibold text-gray-500">
            Vous n'avez pas reçu d'e-mail ?{" "}
          </span>

          <button
            className="btn btn-link btn-active-color-primary fw-bold"
            onClick={resend_activation}
          >
            Réessayez maintenant
          </button>
          <br />
          <span className="fw-semibold text-gray-500">
            Pensez-vous que l'e-mail est incorrect ?
          </span>
          <br />
          <Link to="/register" className="link-primary">
            {" "}
            Se déconnecter
          </Link>
        </div>

        <div className="mb-0">
          <img
            src="assets/media/auth/please-verify-your-email.png"
            className="mw-100 mh-300px theme-light-show"
            alt=""
          />
          <img
            src="assets/media/auth/please-verify-your-email-dark.png"
            className="mw-100 mh-300px theme-dark-show"
            alt=""
          />
        </div>
      </div>
    </General>
  );
};

export default VerifyEmailPage;
