import Auth from "components/auth/Auth";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { register } from "features/auth";
import { Link } from "react-router-dom";

const RegisterPage = () => {
	const dispatch = useDispatch();

	const { registered, loading, error } = useSelector(state => state.user);
	const [passwordsMatch, setPasswordsMatch] = useState(true);
	const [accountCreated, setAccountCreated] = useState(false);
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
		re_password: '',
	});

	const { name, email, password, re_password } = formData;

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

	useEffect(() => {
		setPasswordsMatch(password === re_password);
	}, [password, re_password]);

	const onSubmit = e => {
		e.preventDefault();
		if (password === re_password) {
			dispatch(register({ name, email, password, re_password }));
			setAccountCreated(true);
		}
	}


	if (registered && accountCreated) {
		return <Navigate to='/verify-email' />;
	}

	return (
		<Auth title="Elmajor | Inscription" content='Register'>
			<div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
				<div className="d-flex flex-center flex-column flex-lg-row-fluid mb-10">
					<div className="w-lg-500px p-10">
						<form className="form w-100" onSubmit={onSubmit}>
							<div className="text-center mb-11">
								<h1 className="text-dark fw-bolder mb-3">Inscription</h1>
								<div className="text-gray-500 fw-semibold fs-6">Connectez-vous </div>
							</div>
							<div className="fv-row mb-8">
								<input
									type="text"
									placeholder="Nom"
									name="name"
									autoComplete="on"
									className="form-control bg-transparent"
									onChange={onChange}
									value={name}
									required />
							</div>
							<div className="fv-row mb-8">
								<input
									type="email"
									placeholder="Email"
									name="email"
									autoComplete="on"
									className="form-control bg-transparent"
									onChange={onChange}
									value={email}
									required />
								{error && error.email && <div className="fv-plugins-message-container invalid-feedback">
									<div data-field="confirm-password" data-validator="identical">
										{error.email}
									</div>
								</div>}
							</div>

							<div className="fv-row mb-8" data-kt-password-meter="true">

								<div className="mb-1">
									<div className="position-relative mb-3">
										<input
											className="form-control bg-transparent"
											type="password"
											placeholder="Mot de passe"
											name="password"
											autoComplete="off"
											onChange={onChange}
											value={password}
											required />
										<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
											data-kt-password-meter-control="visibility">
											<i className="ki-duotone ki-eye-slash fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i>
											<i className="ki-duotone ki-eye d-none fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
										</span>
										{error && error.password && <div className="fv-plugins-message-container invalid-feedback">
											<div data-field="confirm-password" data-validator="identical">
												{error.password}
											</div>
										</div>}
									</div>
									<div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
										<div className="flex-grow-1 bg-gray-400 bg-active-info rounded h-5px me-2"></div>
										<div className="flex-grow-1 bg-gray-400 bg-active-info rounded h-5px me-2"></div>
										<div className="flex-grow-1 bg-gray-400 bg-active-info rounded h-5px me-2"></div>
										<div className="flex-grow-1 bg-gray-400 bg-active-info rounded h-5px"></div>
									</div>
								</div>
								<div className="text-muted">Utilisez 8 caractères ou plus avec un mélange de lettres, de chiffres et de symboles.</div>
							</div>
							<div className="fv-row mb-8 position-relative" data-kt-password-meter="true">
								<input
									placeholder="Répéter le mot de passe"
									name="re_password"
									type="password"
									autocomplete="off"
									className="form-control bg-transparent"
									onChange={onChange}
									value={re_password} />
								<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
									data-kt-password-meter-control="visibility">
									<i className="ki-duotone ki-eye-slash fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i>
									<i className="ki-duotone ki-eye d-none fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
								</span>
								{!passwordsMatch && <div className="fv-plugins-message-container invalid-feedback">
									<div data-field="confirm-password" data-validator="identical">
										Le mot de passe et sa confirmation ne sont pas les mêmes
									</div>
								</div>}
							</div>
							<div className="d-grid mb-10">
								{loading ? (
									<button disabled className="btn btn-primary">
										<span className="">S'il vous plaît, attendez...
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									</button>
								) : (
									<button type="submit" className="btn btn-primary">
										<span className="indicator-label">S'inscrire</span>
									</button>
								)}
							</div>
							<div className="text-gray-500 text-center fw-semibold fs-6">Vous avez déjà un compte?
								<Link to="/login" className="link-primary fw-semibold"> se connecter</Link></div>
						</form>
					</div>
				</div>
			</div>
		</Auth>
	);

};

export default RegisterPage;
