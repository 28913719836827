import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Layout from "components/quiz/Layout";
import Question from "components/quiz/Question";
import Exam from "components/quiz/Exam";
import { checkAuth } from "features/auth";
import {
  resetQuestions,
  resetExamQuestions,
  setSelectedCourseId,
  setSelectedModuleId,
  setSelectedSourcesIds,
} from "features/medecine";
import { toggleFullscreen } from "components/utils/fullscreen";

import { Link } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { setIsDarkMode } from "features/homeSlice";

const QuizPage = () => {
  const navigate = useNavigate();
  const { examQuestions, questions } = useSelector((state) => state.medecine);
  const [questionsReset, setQuestionsReset] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [quizComponent, setQuizComponent] = useState(false);
  const { isDarkMode } = useSelector((state) => state.home);

  const handleThemeChange = () => {
    const newThemeMode = isDarkMode ? "light" : "dark";

    dispatch(setIsDarkMode(!isDarkMode));
    document.documentElement.setAttribute("data-bs-theme", newThemeMode);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (questions && questions.length > 0) {
      setQuizComponent("Quiz");
    } else if (examQuestions && examQuestions.length > 0) {
      setQuizComponent("Exam");
    }
    // Dispatch the checkAuth action initially when the component mounts
    dispatch(checkAuth());

    // Create an interval to dispatch the checkAuth action every 5 minutes (300000 milliseconds)
    const interval = setInterval(() => {
      dispatch(checkAuth());
    }, 300000);

    // Clean up the interval when the component unmounts to avoid memory leaks
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, questions, examQuestions]);

  const { isAuthenticated, user } = useSelector((state) => state.user);

  const handleQuitClick = () => {
    dispatch(resetQuestions());
    dispatch(setSelectedCourseId(null));
    dispatch(setSelectedModuleId(null));
    dispatch(setSelectedSourcesIds(null));
    dispatch(resetExamQuestions());
    setQuestionsReset(true);
    navigate("/dashboard");
  };
  if (!user && !isAuthenticated) {
    navigate("/login");
  }

  const handleToggleFullscreen = () => {
    toggleFullscreen();
    if (isFullscreen) {
      setIsFullscreen(false);
    } else {
      setIsFullscreen(true);
    }
  };

  const showSwalQuitLink = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Voulez-vous vraiment quitter cette session ?",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Quitter",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-danger mx-2",
        cancelButton: "btn btn-success",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleQuitClick();
      }
    });
  };

  return (
    <Layout title="Elmajor | Quiz" content="Quiz page">
      <div className="overflow-x-hidden overflow-y-hidden">
        <div className="card card-flush card-xxl ">
          <div className="card-header me-0 ">
            <div className="d-flex align-items-center me-5">
              {/* <Link to="/dashboard" onClick={handleLinkClick}>
                        <img
                          alt="Logo"
                          src="assets/media/logos/demo6.svg"
                          className="theme-light-show h-60px h-lg-100px"
                        />
                        <img
                          alt="Logo"
                          src="assets/media/logos/demo7.png"
                          className="theme-dark-show h-60px h-lg-100px"
                        />
                      </Link> */}
            </div>

            <div className="d-flex align-items-center flex-shrink-0">
              <div className="d-flex align-items-center ms-3 ms-lg-4">
                <button
                  onClick={handleToggleFullscreen}
                  className="btn btn-icon btn-outline-dashed btn-outline btn-outline-primary w-30px h-30px w-lg-40px h-lg-40px"
                >
                  <i
                    className={`fas ${
                      !isFullscreen ? "fa-maximize" : "fa-minimize"
                    } fs-3`}
                  ></i>
                </button>
              </div>
              <div className="d-flex align-items-center ms-3 ms-lg-4">
                <button
                  onClick={handleThemeChange}
                  className="btn btn-icon btn-outline-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px"
                >
                  <i
                    class={`ki-duotone fs-1 ${
                      isDarkMode ? "ki-sun" : "ki-moon"
                    }`}
                  >
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                    <span class="path6"></span>
                    <span class="path7"></span>
                    <span class="path8"></span>
                    <span class="path9"></span>
                  </i>
                </button>
              </div>
              <div className="d-flex align-items-center ms-3 ms-lg-4">
                <button
                  className="btn btn-icon btn-outline-dashed btn-outline btn-outline-danger ms-3 w-30px h-30px w-lg-40px h-lg-40px"
                  data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                  onClick={showSwalQuitLink}
                >
                  <i class="ki-duotone ki-cross-square fs-1 text-danger">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </button>
              </div>
            </div>
          </div>

          <div className="card-body p-2">
            <div className="row " id="kt_block_ui_1_target">
              {quizComponent === "Quiz" && <Question />}
              {quizComponent === "Exam" && <Exam />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default QuizPage;
