import React, { useState } from "react";

const PropositionCheckbox = ({
  proposition,
  index,
  inputRefs,
  currentMyQuestionIndex,
  visitedQuestion,
}) => {
  const [isChecked, setIsChecked] = useState(proposition.isChecked);
  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };
  const getLabelClass = () => {
    if (visitedQuestion) {
      if (proposition.isChecked && proposition.is_correct) {
        return "btn-light-primary";
      } else if (!proposition.isChecked && proposition.is_correct) {
        return "btn-light-primary";
      } else if (proposition.isChecked && !proposition.is_correct) {
        return "btn-active-light-danger";
      }
    }
    return "";
  };

  const getSvgIcon = () => {
    if (visitedQuestion) {
      if (proposition.isChecked && proposition.is_correct) {
        return (
          <span className="svg-icon svg-icon-muted svg-icon-2hx">
            <i class="ki-duotone ki-double-check-circle text-success fs-2x">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </i>
          </span>
        );
      } else if (!proposition.isChecked && proposition.is_correct) {
        return (
          <span className="svg-icon svg-icon-muted svg-icon-2hx">
            <i class="ki-duotone ki-check-circle fs-2x">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </span>
        );
      } else if (proposition.isChecked && !proposition.is_correct) {
        return (
          <span className="svg-icon svg-icon-muted svg-icon-2hx">
            <i class="ki-duotone ki-cross-circle fs-2x">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </span>
        );
      }
    }
    return (
      <span className="svg-icon svg-icon-muted svg-icon-2hx">
        <i class="ki-duotone ki-double-right fs-2x">
          <span class="path1"></span>
          <span class="path2"></span>
        </i>
      </span>
    );
  };

  const getLabelStyle = () => {
    if (visitedQuestion && proposition.isChecked) {
      return { pointerEvents: "none" };
    }
    return {};
  };

  return (
    <>
      <input
        key={proposition.id}
        type="checkbox"
        className="btn-check"
        name={`answer-${proposition.id}`}
        value="apps"
        id={`kt_radio_option_${proposition.id}`}
        ref={(el) => (inputRefs.current[currentMyQuestionIndex] = el)}
        checked={proposition.isChecked}
        onChange={handleCheckboxChange}
      />

      <label
        className={`btn btn-outline btn-outline-dashed ${
          isChecked ? "btn-active-light-primary" : ""
        } ${getLabelClass()} p-1 d-flex align-items-center mb-1`}
        htmlFor={`kt_radio_option_${proposition.id}`}
        style={getLabelStyle()}
      >
        {getSvgIcon()}

        <span className="d-block fw-semibold text-start">
          <span className="text-gray-900 fw-semibold fs-6 p-1">
            {String.fromCharCode(65 + index)}. {proposition.proposition_text}
          </span>
        </span>
      </label>
    </>
  );
};

export default PropositionCheckbox;
