import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "features/auth";
import { setPageVisible } from "features/homeSlice";
import { getStatistics } from "features/medecine";
import GuidedTour from "containers/GuidedTour";
import { handleRestartTour } from "components/dashboard/utils";

const UserInfo = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { statistics } = useSelector((state) => state.medecine);
  const [runTour, setRunTour] = useState(false);

  const handleToggleProfile = () => {
    dispatch(setPageVisible({ page: "profile", visible: true }));
  };

  const handleToggleStatistics = () => {
    if (!statistics) {
      dispatch(getStatistics());
    }
    dispatch(setPageVisible({ page: "statistics", visible: true }));
  };

  return (
    <>
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src="assets/media/svg/avatars/000-boy.svg" />
          </div>
          {user && (
            <div className="d-flex flex-column">
              <div className="fw-bold d-flex align-items-center fs-5">
                {user.name}
                {user && user.profile && (
                  <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                    {user.profile.is_verified}
                  </span>
                )}{" "}
              </div>
              <span className="fw-semibold text-muted fs-7">{user.email}</span>
            </div>
          )}
        </div>
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        <span className="menu-link px-5" onClick={handleToggleProfile}>
          Mon profile
        </span>
      </div>
      <div className="menu-item px-5">
        <span className="menu-link px-5" onClick={handleToggleStatistics}>
          Mes statistiques
        </span>
      </div>
      <div className="menu-item px-5">
        <span
          className="menu-link px-5"
          onClick={() => handleRestartTour(setRunTour)}
        >
          Visite guidée
        </span>
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        <span className="menu-link px-5" onClick={() => dispatch(logout())}>
          Déconnecter
        </span>
      </div>
      {runTour && <GuidedTour runTour={runTour} />}
    </>
  );
};

export default UserInfo;
