import Layout from "components/landing/Layout";
import Navbar from "components/landing/Navbar";
const HomePage = () => {
  return (
    <Layout title="Elmajor">
      <div className="d-flex flex-column flex-root" id="kt_app_root">
        {/* Hero Section */}
        <div className="mb-0 overflow-x-hidden mw-100" id="home">
          <div className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg">
            <Navbar />
            <div className="row justify-content-center">
              <div className="col-lg-6">
                {/* Left Column */}
                <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                  <h1 className="text-white lh-base fw-bold fs-1x fs-lg-4x mb-15">
                    Votre passeport pour le <br />
                    <span
                      style={{
                        background:
                          "linear-gradient(to right, #ff5e00 0%, #9a65db 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      <span id="kt_landing_hero_text">succès aux examens</span>
                    </span>
                  </h1>
                  <a href="/dashboard" className="btn btn-primary">
                    Commencer
                  </a>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center order-second order-lg-last">
                {/* Right Column */}
                <img
                  src="assets/media/illustrations/asset-2.svg"
                  className="w-lg-50 mb-9"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
            <svg
              viewBox="15 12 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        {/* about */}
        <div className="mb-n10 mb-lg-n20 z-index-2" id="about">
          <section className="container my-5">
            <div className="text-center mb-17">
              <h3
                className="fs-2hx text-dark mb-5"
                id="how-it-works"
                data-kt-scroll-offset="{default: 100, lg: 150}"
              >
                À PROPOS DE NOUS
              </h3>
              <div className="fs-5 text-info fw-bold">
                Économisez des tonnes de polycopies avec un seul
                <br />
                outil de révision incroyablement efficace et utile !
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-6">
                <img
                  src="assets/media/illustrations/sketchy-svg/2.svg"
                  className="w-100 min-100 mb-9"
                  alt=""
                />
              </div>
              <div className="col-md-6 py-20">
                <h1 className="fs-2hx text-secondary mb-5 text-uppercase">
                  Chez Elmajor,{" "}
                </h1>
                <p className="fs-2 fw-semibold text-gray-700 mb-5">
                  nous sommes dévoués à rassembler les meilleures ressources
                  pour faciliter votre préparation et garantir votre succès
                  académique.
                </p>
                <p className="fs-2 fw-semibold text-gray-700 mb-5">
                  Nous comprenons les défis auxquels vous êtes confrontés en
                  tant qu'étudiant, c'est pourquoi nous avons créé une
                  plateforme qui regroupe des QCMs provenant de différentes
                  sources. Nous organisons ces QCMs par partie du cours par
                  cours, par module et par année, afin que vous puissiez vous
                  concentrer sur les sujets pertinents pour votre examen.
                </p>
                <a
                  href="/dashboard"
                  className="btn btn-outline btn-outline-primary d-flex flex-center"
                >
                  Commencer
                </a>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-6 order-md-2">
                <img
                  src="assets/media/illustrations/sketchy-svg/15.svg"
                  className="w-100 min-100 mb-9"
                  alt=""
                />
              </div>
              <div className="col-md-6 order-md-1 py-20">
                <h1 className="fs-2hx text-secondary mb-5 text-uppercase">
                  Notre objectif
                </h1>
                <p className="fs-2 fw-semibold text-gray-700 mb-5">
                  est de vous offrir une expérience de révision efficace et
                  agréable. Nous voulons vous aider à dépasser les anciennes
                  méthodes de révision qui étaient souvent fastidieuses et peu
                  productives.
                </p>
                <p className="fs-2 fw-semibold text-gray-700 mb-5">
                  Avec Elmajor, vous pouvez étudier où que vous soyez,
                  simplement en utilisant votre smartphone, votre tablette ou
                  votre ordinateur. Vous n'aurez plus besoin de transporter des
                  piles de polycopiés !{" "}
                </p>
                <a
                  href="/dashboard"
                  className="btn btn-outline btn-outline-primary d-flex flex-center"
                >
                  Commencer
                </a>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-6">
                <img
                  src="assets/media/illustrations/sketchy-svg/7.svg"
                  className="w-100 min-100 mb-9"
                  alt=""
                />
              </div>
              <div className="col-md-6 py-20">
                <h1 className="fs-2hx text-secondary mb-5 text-uppercase">
                  Notre équipe{" "}
                </h1>
                <p className="fs-2 fw-semibold text-gray-700 mb-5">
                  est passionnée et travaille sans relâche pour que la réussite
                  à vos examens soit toujours à votre portée. Votre succès est
                  notre priorité, et nous sommes fiers de faire partie de votre
                  parcours académique.
                </p>
                <p className="fs-2 fw-semibold text-gray-700 mb-5">
                  Rejoignez-nous dès aujourd'hui et commencez à préparer vos
                  examens avec confiance. Ensemble, nous pouvons réaliser de
                  grandes choses et vous aider à atteindre vos objectifs
                  académiques.
                </p>
                <a
                  href="/dashboard"
                  className="btn btn-outline btn-outline-primary d-flex flex-center"
                >
                  Commencer
                </a>
              </div>
            </div>
          </section>
        </div>
        {/* statistics */}
        <div className="mt-sm-n10">
          <div className="landing-curve landing-dark-color z-index-3 mt-5">
            <svg
              viewBox="15 -1 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="pb-15 pt-18 landing-dark-bg">
            <div className="container">
              <div
                className="text-center mt-15 mb-18"
                id="achievements"
                data-kt-scroll-offset="{default: 100, lg: 150}"
              >
                <h3 className="fs-2hx text-white fw-bold mb-5">
                  We Make Things Better
                </h3>
                <div className="fs-5 text-primary fw-bold">
                  Améliorer! , est notre engagement pour un avenir meilleur
                </div>
              </div>
              <div className="d-flex flex-center">
                <div className="d-flex flex-wrap flex-center justify-content-lg-between mb-15 mx-auto w-xl-900px">
                  <div className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain">
                    <i className="ki-duotone ki-data fs-2tx text-secondary mb-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                    </i>
                    <div className="mb-0 text-center">
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div
                          className="min-w-70px"
                          data-kt-countup="true"
                          data-kt-countup-value="18"
                          data-kt-countup-suffix="K+"
                        >
                          0
                        </div>
                      </div>
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                        QCMs
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain">
                    <i className="ki-duotone ki-book-square fs-2tx text-secondary mb-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </i>
                    <div className="mb-0 text-center">
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div
                          className="min-w-70px"
                          data-kt-countup="true"
                          data-kt-countup-value="35"
                          data-kt-countup-suffix="+"
                        >
                          0
                        </div>
                      </div>
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                        Sources
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain">
                    <i className="ki-duotone ki-note-2 fs-2tx text-secondary mb-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </i>
                    <div className="mb-0 text-center">
                      <div className="fs-lg-1hx fs-2x fw-bold text-white d-flex flex-center">
                        <div className="min-w-70px">Illimité</div>
                      </div>
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                        Notes
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain">
                    <i className="ki-duotone ki-book fs-2tx text-secondary mb-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </i>
                    <div className="mb-0 text-center">
                      <div className="fs-lg-1hx fs-2x fw-bold text-white d-flex flex-center">
                        <div className="min-w-70px">Illimité</div>
                      </div>
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                        Playlists
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain">
                    <i className="ki-duotone ki-graph-up fs-2tx text-secondary mb-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                      <span className="path6"></span>
                    </i>
                    <div className="mb-0 text-center">
                      <div className="fs-lg-1hx fs-2x fw-bold text-white d-flex flex-center">
                        <div className="min-w-70px">détaillées</div>
                      </div>
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                        Statistiques
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain">
                    <div className="symbol symbol-50px">
                      <i className="symbol-label bg-transparent ki-duotone ki-update-file fs-2tx text-secondary mb-3">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                      </i>
                      <span className="symbol-badge badge bg-danger start-100">
                        Soon
                      </span>
                    </div>

                    <div className="mb-0 text-center">
                      <div className="fs-lg-1hx fs-2x fw-bold text-white d-flex flex-center">
                        <div className="min-w-70px">Bientôt</div>
                      </div>
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                        Aide-Mémoire
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fs-2 fw-semibold text-muted text-center mb-3">
                <span className="fs-1 lh-1 text-gray-700">“</span>Le chapitre
                que vous apprenez aujourd'hui sauvera la vie de quelqu'un demain
                <span className="fs-1 lh-1 text-gray-700">“</span>
                <br />
                <span className="text-secondary  fw-bold me-1">
                  Soyez attentif
                </span>
              </div>
            </div>
          </div>
          <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 12 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        {/* How it works */}
        <div className="mb-n10 mb-lg-n20 z-index-2">
          <div className="container">
            <div className="text-center py-17">
              <h3
                className="fs-2hx text-dark mb-5"
                id="how-it-works"
                data-kt-scroll-offset="{default: 100, lg: 150}"
              >
                Comment ça fonctionne
              </h3>
              <div className="fs-5 text-primary fw-bold">
                Économisez des tonnes de polycopies avec un seul
                <br />
                outil de révision incroyablement efficace et utile !
              </div>
            </div>
            <div className="row w-100 gy-10 mb-md-20">
              <div className="col-md-4 px-5">
                <div className="text-center mb-10 mb-md-0">
                  <img
                    src="assets/media/illustrations/sigma-svg/8.svg"
                    className="mh-125px mb-9"
                    alt=""
                  />
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-info fw-bold p-5 me-3 fs-3">
                      1
                    </span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">
                      Inscription Rapide
                    </div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-gray-700">
                    Entamez votre aventure en toute simplicité grâce à notre
                    processus d'inscription rapide. Rejoignez-nous sans tracas
                    et explorez nos offres dès maintenant.
                  </div>
                </div>
              </div>

              <div className="col-md-4 px-5">
                <div className="text-center mb-10 mb-md-0">
                  <img
                    src="assets/media/illustrations/sigma-svg/12.svg"
                    className="mh-125px mb-9"
                    alt=""
                  />
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-info fw-bold p-5 me-3 fs-3">
                      2
                    </span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">
                      Offres Exclusives
                    </div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-gray-700">
                    Faites votre choix parmi nos abonnements adaptés à vos
                    besoins spécifiques. Profitez de tarifs compétitifs pour
                    accéder à une gamme complète de ressources.
                  </div>
                </div>
              </div>

              <div className="col-md-4 px-5">
                <div className="text-center mb-10 mb-md-0">
                  <img
                    src="assets/media/illustrations/sigma-svg/17.svg"
                    className="mh-125px mb-9"
                    alt=""
                  />
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-info fw-bold p-5 me-3 fs-3">
                      3
                    </span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">
                      Expérience Enrichissante
                    </div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-gray-700">
                    Plongez-vous dans une expérience éducative inégalée.
                    Bénéficiez d'un contenu riche et diversifié pour une
                    préparation aux examens gratifiante et réussie.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* pricing */}
        <div className="mt-20" id="pricing">
          <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 -1 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="py-10 landing-dark-bg">
            <div className="container">
              <div className="d-flex flex-column container pt-lg-20">
                <div className="mb-13 text-center">
                  <h1
                    className="fs-2hx fw-bold text-white mb-5"
                    id="pricing"
                    data-kt-scroll-offset="{default: 100, lg: 150}"
                  >
                    Des tarifs clairs pour une simplicité garantie
                  </h1>
                  <div className="text-gray-600 fw-semibold fs-5">
                    Simplicité à son meilleur : Notre tarification claire
                    garantit une expérience fluide,
                    <br />
                    sans frais cachés, juste une valeur directe et transparente
                  </div>
                </div>
                <div className="text-center" id="kt_pricing">
                  <div className="row g-10">
                    <div className="col-xl-4">
                      <div className="d-flex h-100 align-items-center">
                        <div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                          <div className="mb-7 text-center">
                            <h1 className="text-dark mb-5 fw-boldest">
                              Externat
                            </h1>
                            <div className="text-gray-400 fw-semibold mb-5">
                              Entre 1ère et 6ème année de médecine
                            </div>
                            <div className="text-center">
                              <span className="mb-2">à partir de </span>
                              <span className="mb-2 text-secondary">DZD</span>
                              <span
                                className="fs-3x fw-bold text-secondary"
                                data-kt-plan-price-month="99"
                                data-kt-plan-price-annual="999"
                              >
                                1500
                              </span>
                            </div>
                          </div>
                          <div className="w-100 mb-10">
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                QCMs Organisés pour le Cycle Préclinique
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Un Seul Utilisateur
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Mode de Révision
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Modes d'Examen et d'Examen Blancs
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Notes et Playlists Personnelles
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Accès à des Sources Fiables et Spécifiques
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                          </div>
                          <a href="/dashboard" className="btn btn-primary">
                            Commencer
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="d-flex h-100 align-items-center">
                        <div className="w-100 d-flex flex-column flex-center rounded-3 bg-primary py-20 px-10">
                          <div className="mb-7 text-center">
                            <h1 className="text-white mb-5 fw-boldest">
                              Résidanat
                            </h1>
                            <div className="text-white opacity-75 fw-semibold mb-5">
                              7ème année de médecine
                            </div>
                            <div className="text-center">
                              <span className="mb-2 text-white">DZD</span>
                              <span
                                className="fs-3x fw-bold text-white"
                                data-kt-plan-price-month="199"
                                data-kt-plan-price-annual="1999"
                              >
                                5500
                              </span>
                            </div>
                          </div>
                          <div className="w-100 mb-10">
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-white text-start pe-3">
                                QCMs Organisés par Cours et Module
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-white text-start pe-3">
                                Un Seul Utilisateur
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-white text-start pe-3">
                                Mode de Révision
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-white text-start pe-3">
                                Modes d'Examen et d'Examen Blancs
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-white text-start pe-3">
                                Notes et Playlists Personnelles
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-white text-start pe-3">
                                Accès à des Sources Fiables et Spécifiques
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                          </div>
                          <a
                            href="/dashboard"
                            className="btn btn-color-primary btn-active-light-primary btn-light"
                          >
                            Commencer
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="d-flex h-100 align-items-center">
                        <div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                          <div className="mb-7 text-center">
                            <h1 className="text-dark mb-5 fw-boldest">
                              Par module
                            </h1>
                            <div className="text-gray-400 fw-semibold mb-5">
                              Pas de contraintes
                            </div>
                            <div className="text-center">
                              <span className="mb-2">à partir de </span>
                              <span className="mb-2 text-secondary">DZD</span>
                              <span
                                className="fs-3x fw-bold text-secondary"
                                data-kt-plan-price-month="99"
                                data-kt-plan-price-annual="999"
                              >
                                200
                              </span>
                            </div>
                          </div>
                          <div className="w-100 mb-10">
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Liberté de choisir uniquement les modules qui
                                vous intéressent
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Un Seul Utilisateur
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Mode de Révision
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Modes d'Examen et d'Examen Blancs
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Notes et Playlists Personnelles
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-7 text-gray-800 text-start pe-3">
                                Accès à des Sources Fiables et Spécifiques
                              </span>
                              <i className="ki-duotone ki-check-circle fs-1 text-success">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                          </div>
                          <a href="/dashboard" className="btn btn-primary">
                            Commencer
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 12 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        {/* Testimonials */}
        <div className="mt-20 mb-n20 position-relative z-index-2">
          <div className="container">
            <div className="text-center mb-17">
              <h3
                className="fs-2hx text-dark mb-5"
                id="clients"
                data-kt-scroll-offset="{default: 125, lg: 150}"
              >
                Témoignages de nos clients
              </h3>
              <div className="fs-5 text-primary fw-bold">
                La réussite de nos étudiants est notre plus grande satisfaction
              </div>
            </div>
            <div
              id="kt_carousel_2_carousel"
              className="carousel carousel-custom slide g-lg-10 mb-10 mb-lg-20"
              data-bs-ride="carousel"
              data-bs-interval="8000"
            >
              {/* Heading */}
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                {/* Label */}
                <span className="fs-4 fw-bold pe-2"></span>
                {/* Carousel Indicators */}
                <ol className="p-0 m-0 carousel-indicators carousel-indicators-bullet">
                  <li
                    data-bs-target="#kt_carousel_2_carousel"
                    data-bs-slide-to="0"
                    className="ms-1 active"
                  ></li>
                  <li
                    data-bs-target="#kt_carousel_2_carousel"
                    data-bs-slide-to="1"
                    className="ms-1"
                  ></li>
                  <li
                    data-bs-target="#kt_carousel_2_carousel"
                    data-bs-slide-to="2"
                    className="ms-1"
                  ></li>
                  <li
                    data-bs-target="#kt_carousel_2_carousel"
                    data-bs-slide-to="3"
                    className="ms-1"
                  ></li>
                  <li
                    data-bs-target="#kt_carousel_2_carousel"
                    data-bs-slide-to="4"
                    className="ms-1"
                  ></li>
                </ol>
                {/* End Carousel Indicators */}
              </div>
              {/* End Heading */}

              {/* Carousel */}
              <div className="carousel-inner pt-8 text-center">
                <div className="carousel-item active">
                  <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                    <div className="mb-7">
                      <div className="rating mb-6 flex-center">
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                      </div>
                      <div className="fs-2 fw-bold text-dark mb-3">
                        Maroua ABDELLI
                      </div>
                      <div className="text-gray-700 fw-semibold fs-4">
                        Pour notre promo on n'a pas eu la chance d'avoir "el
                        major" qu'au dernier trimestre moment
                        <br />
                        de stress alors grâce à la plateforme j'ai pu profiter
                        de chaque instant qui me reste pour <br />
                        réviser faire des qcm d'une part pendant les journées de
                        stage et les gardes et d'autre part <br />
                        de m'organiser comme ça me permettait de faire des qcm
                        de différentes sources de différents <br />
                        modules en simple clics partout et à tout moment.
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <span className="text-dark fw-bold text-hover-primary fs-6">
                          Endocrinologie
                        </span>
                        <span className="text-muted d-block fw-bold">
                          16,403{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                    <div className="mb-7">
                      <div className="rating mb-6 flex-center">
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                      </div>
                      <div className="fs-2 fw-bold text-dark mb-3">
                        Ikram ABUDARWISH
                      </div>
                      <div className="text-gray-700 fw-semibold fs-4">
                        L’application El major m’a été d’une très grande aide vu
                        que je l’ai utilisée au moment opportun vers les 2
                        derniers mois .<br />
                        Très pratique , englobe les sources essentielles de qcms
                        avec leurs explications en commentaire . N’oubliez pas
                        de faire <br />
                        vos cours correctement et les compléter avec cette
                        application .<br />
                        Bon courage à tous
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <span className="text-dark fw-bold text-hover-primary fs-6">
                          Cardiologie
                        </span>
                        <span className="text-muted d-block fw-bold">
                          16,319{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                    <div className="mb-7">
                      <div className="rating mb-6 flex-center">
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                      </div>
                      <div className="fs-2 fw-bold text-dark mb-3">
                        Dounia Imane DRIZA
                      </div>
                      <div className="text-gray-700 fw-semibold fs-4">
                        j'ai commencé l'utilisation de la plateforme el major 3
                        mois avant l'examen, le plus grand <br />
                        avantage c'est de pouvoir sélectionner tout les sources
                        possibles pour un cours ; <br />
                        qcm externat , Ecn , Training , diagest.. et de les
                        travailler tous ensemble en un seul clic. <br />
                        grâce a el major je faisais une plusieurs centaines de
                        qcm par jour en peu de temps ! <br />
                        Je le recommande vivement surtout pour ceux qui aime
                        bosser sur des supports numériques.
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <span className="text-dark fw-bold text-hover-primary fs-6">
                          Radiologie
                        </span>
                        <span className="text-muted d-block fw-bold">
                          15,944{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                    <div className="mb-7">
                      <div className="rating mb-6 flex-center">
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                      </div>
                      <div className="fs-2 fw-bold text-dark mb-3">
                        Mohammed Abdallah KALBEZ
                      </div>
                      <div className="text-gray-700 fw-semibold fs-4">
                        Mon expérience avec la plate-forme elmajor était très
                        positive, j'ai bossé les qcm avec pendant <br />
                        les 4 5 derniers mois et ça m'a permis de gagner pas mal
                        de temps, les diverses sources y sont, <br />
                        le tt corrigé et commenté Maintenant je suis nouveau
                        résident en Cardiologie. Et j'ai bcp bénéficié de la
                        plate-forme surtout <br />
                        les dernières semaines ou il est nécessaire d'enchaîner
                        un max de qcm. Le plus important est biensur <br />
                        de saisir une bonne base sur les cours inclus au
                        residanat, et les maîtriser à l'aide des qcm.
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <span className="text-dark fw-bold text-hover-primary fs-6">
                          Cardiologie
                        </span>
                        <span className="text-muted d-block fw-bold">
                          15,556{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                    <div className="mb-7">
                      <div className="rating mb-6 flex-center">
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                        <div className="rating-label me-2 checked">
                          <i className="ki-duotone ki-star fs-5"></i>
                        </div>
                      </div>
                      <div className="fs-2 fw-bold text-dark mb-3">
                        Wissem Batoul BENMANSOUR
                      </div>
                      <div className="text-gray-700 fw-semibold fs-4">
                        C'est Dr.Benmansour Wissem Résidente en ORL. J'ai eu
                        l'opportunité de faire usage de <br />
                        l'Application Elmajor, je ne peux qu'en témoigner un
                        grand bien. En effet, elle est <br />
                        exhaustive(elle englobe toutes les sources requises pour
                        une bonne préparation au concours) <br />
                        possédant une capacité extraordinaire de brasser
                        pratiquement l'essentiel du programme <br />
                        et par conséquent, elle m'a épargnée beaucoup de temps.
                        Bref, elle reste très utile et <br />
                        efficace ainsi, je la recommande vivement aux futurs
                        candidats
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <span className="text-dark fw-bold text-hover-primary fs-6">
                          ORL
                        </span>
                        <span className="text-muted d-block fw-bold">
                          14,972{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Carousel */}
            </div>
            <div
              className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
              style={{
                background: "linear-gradient(90deg, #ff5e00 0%, #9a7bdb 100%)",
              }}
            >
              <div className="my-2 me-5">
                <div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">
                  Lancez-vous avec Elmajor,
                  <span className="fw-normal">
                    {" "}
                    Boostez votre apprentissage !
                  </span>
                </div>
                <div className="fs-6 fs-lg-5 text-white fw-semibold opacity-75">
                  Rejoignez plus de 500 étudiants pour rester en avance !
                </div>
              </div>
              <a
                href="/dashboard"
                className="btn btn-lg btn-success border-2 btn-outline-white flex-shrink-0 my-2"
              >
                Commencer
              </a>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="mb-0">
          <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 -1 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div className="landing-dark-bg pt-20">
            <div className="container">
              <div className="row py-10 py-lg-20">
                <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                  <div className="rounded landing-dark-border p-9 mb-10">
                    <h2 className="text-white">
                      Voulez-vous partager un blog ?
                    </h2>
                    <span className="fw-normal fs-4 text-gray-700">
                      Envoyez-nous un e-mail à
                      <a
                        href="mailto:team@elmajor.com"
                        className="text-white opacity-50 text-hover-primary"
                      >
                        {" "}
                        team@elmajor.com
                      </a>
                    </span>
                  </div>
                  <div className="rounded landing-dark-border p-9">
                    <h2 className="text-white">Avez-vous un problème ?</h2>
                    <span className="fw-normal fs-4 text-gray-700">
                      Envoyez-nous un e-mail à
                      <a
                        href="mailto:support@elmajor.com"
                        className="text-white opacity-50 text-hover-primary"
                      >
                        {" "}
                        support@elmajor.com
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 ps-lg-16">
                  <div className="d-flex justify-content-center">
                    <div className="d-flex fw-semibold flex-column ms-lg-20">
                      <h4 className="fw-bold text-gray-400 mb-6">
                        Restez Connecté
                      </h4>
                      <a
                        href="https://web.facebook.com/elmajor.medecine"
                        className="mb-6"
                      >
                        <img
                          src="assets/media/svg/social-logos/facebook-4.svg"
                          className="h-20px me-2"
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                          Facebook
                        </span>
                      </a>
                      <a
                        href="https://www.instagram.com/elmajor.medecine"
                        className="mb-6"
                      >
                        <img
                          src="assets/media/svg/social-logos/instagram-2-1.svg"
                          className="h-20px me-2"
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                          Instagram
                        </span>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCQ5h7MttkuVWtcwTGvaWQEA"
                        className="mb-6"
                      >
                        <img
                          src="assets/media/svg/social-logos/youtube-play.svg"
                          className="h-20px me-2"
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                          Youtube
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="landing-dark-separator"></div>
            <div className="container">
              <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                <div className="d-flex align-items-center order-2 order-md-1">
                  <a href="/">
                    <img
                      alt="Logo"
                      src="assets/media/logos/landing.svg"
                      className="h-30px h-md-70px"
                    />
                  </a>
                  <span
                    className="mx-5 fs-6 fw-semibold text-gray-600 pt-1"
                    href="https://elmajor.com"
                  >
                    &copy; 2023 Elmajor plateform.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      </div>
    </Layout>
  );
};

export default HomePage;
