import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Auth = ({ title, content, children }) => (
    <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={content} />
            <link rel="shortcut icon" href="/assets/media/logos/favicon.ico" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700" />
            <link rel="stylesheet" href="/assets/plugins/global/plugins.bundle.css" />
            <link rel="stylesheet" href="/assets/css/style.bundle.css" />

            <script src="/assets/plugins/global/plugins.bundle.js"/>
            <script src="/assets/js/scripts.bundle.js"/>
            <script src="/assets/js/custom/authentication/sign-in/general.js"></script>
        </Helmet>
        <div className="d-flex flex-column flex-root" id="kt_app_root">

            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                {children}
                <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{backgroundColor : "#111329"}}>
                    <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
                        <Link to="/" className="mb-0 mb-lg-12">
                            <img alt="Logo" src="/assets/media/logos/demo-transparent-1.png" className="h-60px h-lg-200px" />
                        </Link>
                        <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">Apprendre, Réussir et Sauver</h1>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default Auth;