import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const Layout = ({ title, children }) => {
  const isDarkMode = useSelector((state) => state.home.isDarkMode);

  useEffect(() => {
    const defaultThemeMode = "light";
    let themeMode;

    if (document.documentElement) {
      // Check if the data-bs-theme-mode attribute is present
      if (document.documentElement.hasAttribute("data-bs-theme-mode")) {
        themeMode = document.documentElement.getAttribute("data-bs-theme-mode");
      } else {
        // Use isDarkMode state instead of localStorage
        themeMode = isDarkMode ? "dark" : defaultThemeMode;
      }

      // Set the theme attribute on the document element
      document.documentElement.setAttribute("data-bs-theme", themeMode);
    }
  }, [isDarkMode]);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="content" />
        <meta charset="utf-8" />
        <meta
          name="keywords"
          content="Elmajor, plateforme médicale, étudiants en médecine, préparation aux examens"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Elmajor - Plateforme pour étudiants en médecine pour la préparation aux examens"
        />
        <meta property="og:url" content="https://www.elmajor.com" />
        <meta property="og:site_name" content="Elmajor" />

        <link rel="canonical" href="https://www.elmajor.com" />
        <link rel="shortcut icon" href="MainIcon.svg" />
        <link
          rel="stylesheet"
          href="https://fonts.cdnfonts.com/css/archia?styles=28736"
        />
        <link
          href="assets/plugins/global/plugins.bundle.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="assets/css/style.bundle.css"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>

      <body
        id="kt_body"
        data-bs-spy="scroll"
        data-bs-target="#kt_landing_menu"
        className="bg-white position-relative app-blank "
      >
        {children}
      </body>
    </>
  );
};

export default Layout;
